import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Une gestion responsable des webfonts",
  "description": "Une solution mise en place pour éviter l’apparition d’un FOIT avec les webfonts.",
  "date": "2015-06-25T00:00:00.000Z",
  "path": "/articles/une-gestion-responsable-des-webfonts/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Jusqu’à très récemment, j’avais tendance à gérer mes webfonts de manière basique en les appelant directement dans mon CSS, que ce soit via CDN (Google webfonts) ou en hébergement personnel. Mais cette manière de faire est propice à l’apparition du FOIT, qui peut être très pénalisant selon les navigateurs. Voici une solution que j’ai mise en place dernièrement, et dont je suis pleinement satisfait.`}</p>
    <h2>{`Quelques précisions`}</h2>
    <p>{`La notion de `}<em parentName="p">{`FOIT`}</em>{` ne parlant pas à tout le monde, commençons par une petite explication : tant que votre navigateur n’a pas téléchargé les `}<em parentName="p">{`webfonts`}</em>{` de votre site, le texte ainsi stylé reste invisible pendant un certain temps avant que le navigateur décide d’afficher les polices que vous avez définies par défaut. C’est ce qu’on appelle le `}<em parentName="p">{`FOIT`}</em>{`, pour `}<em parentName="p">{`Flash Of Invisible Text`}</em>{`.`}</p>
    <p>{`Le problème, c’est que ce temps varie selon les navigateurs; et s’il reste raisonnable sur Chrome ou Firefox (de l’ordre de 3 secondes), il peut aller jusqu’à 30 secondes pour Safari iOS par exemple.`}</p>
    <figure>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "824px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8b09e695353461b8d17f3d10d85bffce/582e9/webfonts-invision.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.06796116504856%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Exemple de site avec FOIT",
            "title": "Exemple de site avec FOIT",
            "src": "/static/8b09e695353461b8d17f3d10d85bffce/10dd0/webfonts-invision.png",
            "srcSet": ["/static/8b09e695353461b8d17f3d10d85bffce/2b93d/webfonts-invision.png 206w", "/static/8b09e695353461b8d17f3d10d85bffce/799e5/webfonts-invision.png 412w", "/static/8b09e695353461b8d17f3d10d85bffce/10dd0/webfonts-invision.png 824w", "/static/8b09e695353461b8d17f3d10d85bffce/04585/webfonts-invision.png 1236w", "/static/8b09e695353461b8d17f3d10d85bffce/582e9/webfonts-invision.png 1400w"],
            "sizes": "(max-width: 824px) 100vw, 824px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`
    Exemple de FOIT : sur ce site, il m’a fallu une dizaine de secondes pour
    pouvoir accéder au contenu – malgré ma connexion en WiFi.
  `}</figcaption>
    </figure>
    <p>{`Si l’on considère en outre qu’un site doit en moyenne se charger en 1 seconde avec une connexion rapide, ne pas pouvoir accéder au contenu du site avant 3 secondes n’est de toute façon pas l’idéal.`}</p>
    <h2>{`La solution au problème`}</h2>
    <p><a parentName="p" {...{
        "href": "http://crocodillon.com/blog/non-blocking-web-fonts-using-localstorage"
      }}>{`Plusieurs`}</a>{` `}<a parentName="p" {...{
        "href": "http://jaicab.com/localFont/"
      }}>{`techniques`}</a>{` ont émergé récemment, mais celle qui a retenu mon attention est celle mise en place par `}<a parentName="p" {...{
        "href": "https://www.filamentgroup.com/lab/font-events.html"
      }}>{`filament group`}</a>{`.`}</p>
    <p>{`L’idée est d’utiliser par défaut les polices `}<em parentName="p">{`websafe`}</em>{` (cf. `}<a parentName="p" {...{
        "href": "http://www.cssfontstack.com/"
      }}>{`CSS Font Stack`}</a>{`) et de prévoir une classe contenant les `}<em parentName="p">{`webfonts`}</em>{`, par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`body`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Sans-serif`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.font-loaded`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Open Sans'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Sans-serif`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`On utilise ensuite le loader JS `}<a parentName="p" {...{
        "href": "https://github.com/bramstein/fontfaceobserver"
      }}>{`Font Face Observer`}</a>{` créé par `}<a parentName="p" {...{
        "href": "https://twitter.com/bram_stein"
      }}>{`Bram Stein`}</a>{` pour détecter le moment où toutes les `}<em parentName="p">{`webfonts`}</em>{` seront loadées, et une fois que c’est fait on ajoute notre classe `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-loaded`}</code>{` :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`w`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`documentElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`className`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`indexOf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'fonts-loaded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` fontA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`w`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`FontFaceObserver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Open Sans'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`weight`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`300`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` fontB `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`w`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`FontFaceObserver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Open Sans'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`weight`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`300`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'italic'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Promise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`all`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`fontA`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`check`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fontB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`check`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`documentElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`className `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`' fonts-loaded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    localStorage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'fontsLoaded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`L’approche de filament group privilégie l’utilisation de cookies, mais quant à moi j’ai préféré me rabattre sur `}<em parentName="p">{`localStorage`}</em>{`. On crée avec celui-ci un item `}<code parentName="p" {...{
        "className": "text"
      }}>{`fontsLoaded`}</code>{` grâce auquel on pourra ajouter dans notre `}<code parentName="p" {...{
        "className": "text"
      }}>{`<head>`}</code>{` la classe `}<code parentName="p" {...{
        "className": "text"
      }}>{`fonts-loaded`}</code>{` à notre html, comme ceci :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`if`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`localStorage`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`getItem`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'fontsLoaded'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
    document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`documentElement`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`className `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`+=`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`' fonts-loaded'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Ainsi, tant que cet item sera encore présent dans notre `}<em parentName="p">{`localStorage`}</em>{`, la classe en question sera appliquée avant le rendu de la page.`}</p>
    <h2>{`Une future alternative`}</h2>
    <p>{`L’approche décrite ici me paraît pour l’instant la plus efficace, en attendant une meilleure solution… Celle-ci pourrait bien venir de la proposition `}<em parentName="p">{`CSS font-rendering`}</em>{` dont le but est de contrôler directement via CSS le comportement d’affichage de nos `}<em parentName="p">{`webfonts`}</em>{`. Je vous invite à consulter son `}<a parentName="p" {...{
        "href": "https://github.com/KenjiBaheux/css-font-rendering"
      }}>{`repo Github`}</a>{` pour de plus amples informations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      